/*reset begins*/
html, body, h1, h2, h3, h4, h5, h6, a, img, form, label, table, tbody, tfoot, thead, tr, th, td, ul, ol, li, figure, figcaption {
  margin: 0;
  padding: 0;
}

img {
  border: 0;
}

table {
  border-spacing: 0;
}

tr, td {
  vertical-align: top;
}

input::-ms-clear {
  display: none;
}

/*reset ends*/
/*base styles begins*/
body {
  font:15px/20px 'Roboto', Arial, Helvetica, sans-serif;
  color:#333136;
}

a:link,
a:visited,
a:hover,
a:active,
a:focus {
  color:#602f9c;
  border-bottom:1px solid rgba(80, 122, 173, 0.4);
  text-decoration:none;
  -moz-transition:color 0.3s ease, background 0.3s ease, border 0.3s ease;
  -webkit-transition:color 0.3s ease, background 0.3s ease, border 0.3s ease;
  transition:color 0.3s ease, background 0.3s ease, border 0.3s ease;
}

a:visited {

}

a:hover {
  color:#ed731c;
  border-bottom-color:transparent;
}

a.gray:link,
a.gray:visited,
a.gray:hover,
a.gray:active,
a.gray:focus {
  color:#999;
  border-bottom:1px solid #cfcfcf;
  text-decoration:none;
  display:inline;
}

a.gray:hover {
  color:#5688b6;
  border-bottom-color:#bbcfe2;
}

a.orange:link,
a.orange:visited,
a.orange:hover,
a.orange:active,
a.orange:focus {
  color:#ed731c;
  border-bottom:1px solid rgba(237, 115, 28, 0.4);
  text-decoration:none;
  display:inline;
}

a.orange:hover {
  color:#507aad;
  border-bottom-color:transparent;
}

a.yellow:link,
a.yellow:visited,
a.yellow:hover,
a.yellow:active,
a.yellow:focus {
  color:#ffed00;
  border-bottom:0;
  text-decoration:none;
  display:inline;
}

a.yellow:hover {

}

.fake-link {
  display:inline-block;
  color:inherit !important;
  border-bottom:0 !important;
}

.default-size {
  font-size:15px !important;
}

.default-lh {
  line-height:20px !important;
}

.bold {
  font-weight:bold !important;
}

.normal {
  font-weight:normal !important;
}

.icon {
  background-repeat:no-repeat;
  display:inline-block !important;
  position:relative;
}

h1, .title {
    font-size: 30px;
    line-height: 1.41;
}

h2, .title-sm {

}

h3, .title-vsm {

}

h4 {

}

.content ul {

}

.content ul li {

}

.content ul li:before {

}

.content ol {

}

.content ol li {

}

.content ol li span {

}

.fleft {
  float: left !important;
}

.fright {
  float: right !important;
}

.fnone {
  float: none !important;
}

.block {
  display: block !important;
}

.in-block {
  display: inline-block !important;
}

.in-table {
  display:inline-table !important;
}

.inline {
  display:inline !important;
}

.none {
  display: none !important;
}

.hidden {
  display:none !important;
}

.relative {
  position:relative !important;
}

.absolute {
  position: absolute !important;
}

.static {
  position: static !important;
}

.vtop {
  vertical-align:top !important;
}

.nowrap {
  white-space:nowrap !important;
}

.default-color {
  color:#202020 !important;
}

.gray {
  color:#999;
}

.blue {
  color:#507aad !important;
}

.orange {
  color:#ed731c !important;
}

.uppercase {
  text-transform:uppercase;
}

.lowercase {
  text-transform:lowercase;
}

.o-hidden {
  overflow:hidden !important;
}

.dotted {
  border-bottom-style:dotted !important;
}

.button,
.button:link,
.button:visited,
.button:hover,
.button:active,
.button:focus {
    background: #602f9c;
    box-shadow: 0 10px 10px 0 rgba(96, 47, 156, 0.2);
    font: bold 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
    color: #fff;
    display: inline-block;
    padding: 20px 97px;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
}

.button:hover {

}

input.button {

}

.button[disabled] {
    background: #888;
    cursor: default;
}

.button-sm,
.button-sm:link,
.button-sm:visited,
.button-sm:hover,
.button-sm:active,
.button-sm:focus {
    background: #602f9c;
    box-shadow: 0 5px 5px 0 rgba(96, 47, 156, 0.2);
    font: bold 12px/1.41 'Roboto', Arial, Helvetica, sans-serif;
    color: #fff;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
}

.button-sm:hover {

}

input.button-sm {

}

.button-sm[disabled] {
    background: #888;
    cursor: default;
}

.button-yellow,
.button-yellow:link,
.button-yellow:visited,
.button-yellow:hover,
.button-yellow:active,
.button-yellow:focus {
    background: #ffed00;
    box-shadow: 0 5px 5px 0 rgba(96, 47, 156, 0.2);
    font: bold 18px/1.41 'Roboto', Arial, Helvetica, sans-serif;
    color: #333136;
    display: inline-block;
    padding: 10px 33px;
    border-radius: 100px;
    outline: none;
    cursor: pointer;
    margin-bottom: 35px;
}

.button-yellow:hover {

}

input.button-yellow {

}

.button-yellow[disabled] {
    background: #888;
    cursor: default;
}

body .bbottom-r {
  border-bottom-width:0 !important;
}

.link-inner {
  border-bottom-color:inherit;
  border-bottom-width:1px;
  border-bottom-style:inherit;
}

.wrapper-right {
  text-align:right !important;
}

.wrapper-center {
  text-align:center !important;
}

.wrapper-left {
  text-align:left !important;
}

.wrapper-justify {
  text-align:justify !important;
}

.wrapper-justify-in-blocks:after {
  content:'';
  width:100%;
  display:inline-block;
}

.mb18 {
    margin-bottom: 18px !important;
}

.mb26 {
    margin-bottom: 26px !important;
}

.mb37 {
    margin-bottom: 37px !important;
}

.mb80 {
    margin-bottom: 80px !important;
}

.pb0 {
    padding-bottom: 0 !important;
}

.pb19 {
    padding-bottom: 19px !important;
}

.pl5 {
    padding-left: 5px !important;
}

.pr5 {
    padding-right: 5px !important;
}

.rouble {
  font-family:'Rouble';
}

@media print {
  .rouble {
    font-family:Arial, Helvetica, sans-serif;
    font-size:0;
    line-height:0;
    display:inline-table;
    color:transparent;
  }

  .rouble:before {
    content:'р.';
    font-size:15px;
    line-height:20px;
    color:#202020;
  }
}

hr.hr {
  border:0;
  border-top:1px solid #ccc;
  margin:22px 0 34px 0;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
/*base styles ends*/

/*pages begins*/
.page-wrapper {
    padding-bottom: 50px;
    min-width: 1240px;
}

.container {
  max-width:940px;
  padding:0 33px;
  margin:0 auto;
}

.header {
    background: #602f9c;
    padding: 19px 140px;
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
}

    .header__controls {
        float: right;
        padding-top: 10px;
    }

.header-filler {
    height: 80px;
}

.logo,
.logo:link,
.logo:visited,
.logo:hover,
.logo:active,
.logo:focus {
    background: url('../images/icon-logo@3x.png') no-repeat 0 50%;
    background-size: 63px 42px;
    min-height: 42px;
    float: left;
    border-bottom: 0;
    font: bold 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
    color: #fff;
    padding-left: 83px;
}

    .logo__title {
        display: block;
        margin: 4px 0;
    }

    .logo__about {
        display: block;
        font-size: 12px;
        opacity: 0.6;
    }

.i-link,
.i-link:link,
.i-link:visited,
.i-link:hover,
.i-link:active,
.i-link:focus {
    background-position: 0 50%;
    background-size: auto 24px;
    background-repeat: no-repeat;
    min-height: 24px;
    padding-left: 36px;
    margin-left: 38px;
    font: bold 16px/24px 'Roboto', Arial, Helvetica, sans-serif;
    color: #fff;
    display: inline-block;
    vertical-align: top;
    border-bottom: 0;
}

.i-link_about {
    background-image: url('../images/icon-question@3x.png');
}

.i-link_postcards {
    background-image: url('../images/icon-letter@3x.png');
}

.i-link_login {
    background-image: url('../images/icon-login@3x.png');
}

.preloaded-cards {

}

.preloaded-card {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 275px;
    height: 390px;
    display: inline-block;
    vertical-align: top;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
}

.text-input-help {
    margin-bottom: 40px;
    position: relative;
}

    .text-input-help__label {

    }

    .text-input-help__label input {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #d6d6d7;
        padding: 0 0 9px;
        outline: none;
        font: 14px/1.125 'Roboto', Arial, Helvetica, sans-serif;
    }

    .text-input-help__placeholder {
        position: absolute;
        font: 14px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        top: 4px;
        transition: all 0.3s ease;
    }

    .text-input-help__placeholder.active {
        font: 12px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        opacity: 0.6;
        top: -15px;
    }

    .text-input-help__prefix {
        position: absolute;
        left: -20px;
        top: 4px;
        font: 14px/1.125 'Roboto', Arial, Helvetica, sans-serif;
    }

    .text-input-help__list {
        background: #fff;
        position: absolute;
        top: 97%;
        left: 0;
        right: 0;
        z-index: 200;
        border: 0.5px solid #d6d6d7;
        max-height: 250px;
        overflow: auto;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    }

    .text-input-help__list__item {
        margin: 5px 10px;
        border-bottom: 1px solid #d6d6d7;
        padding-bottom: 5px;
        font: 13px/1.125 'Roboto', Arial, Helvetica, sans-serif;
    }

.auth-popup-w {
    background: rgba(51, 49, 54, 0.3);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.auth-popup {
    background: #fff;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 120px;
    width: 440px;
    box-sizing: border-box;
    padding: 45px 50px;
}

    .auth-popup__closer {
        background: url(../images/icon-closer-gray@3x.png) no-repeat 50%;
        background-size: contain;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        transition: transform 0.3s ease;
    }

    .auth-popup__closer:hover {
        transform: rotate( 90deg );
    }

    .auth-popup__inner {

    }

    .auth-popup__title {
        font: bold 32px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        text-align: center;
        margin-bottom: 40px;
    }

    .auth-popup__send {
        background: none;
        font: bold 14px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        color: #602f9c;
        border: 0;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 3px;
    }

    .auth-popup__send[disabled] {
        opacity: 0.3;
        cursor: default;
    }

.slick-slider_custom {
    width: 1160px;
    margin: 0 auto;
}

    .slick-slider_custom__title {
        font: bold 20px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        text-transform: uppercase;
        text-align: center;
        margin: 55px 0 35px;
    }

    .slick-slider_custom .slick-slide {
        text-align: center;
    }

    .slick-slider_custom .slick-slider_custom__slide-inner {
        outline: none;
    }

    .slick-slider_custom .slick-prev {
        background-image: linear-gradient(to top, #602f9c, #7e2fde);
        width: 50px;
        height: 50px;
        border-radius: 100%;
        left: -88px;
        transition: all 0.3s ease;
    }

    .slick-slider_custom .slick-prev:focus {
        background-image: linear-gradient(to top, #602f9c, #7e2fde);
    }

    .slick-slider_custom .slick-prev:hover {
        background-image: linear-gradient(to top, #602f9c, #7e2fde);
        opacity: 0.2;
    }

    .slick-slider_custom .slick-prev:before {
        background: url('../images/icon-arrow-left-white@3x.png') no-repeat 50%;
        background-size: 19%;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .slick-slider_custom .slick-next {
        background-image: linear-gradient(to top, #602f9c, #7e2fde);
        width: 50px;
        height: 50px;
        border-radius: 100%;
        right: -88px;
        transition: all 0.3s ease;
    }

    .slick-slider_custom .slick-next:focus {
        background-image: linear-gradient(to top, #602f9c, #7e2fde);
    }

    .slick-slider_custom .slick-next:hover {
        background-image: linear-gradient(to top, #602f9c, #7e2fde);
        opacity: 0.2;
    }

    .slick-slider_custom .slick-next:before {
        background: url('../images/icon-arrow-right-white@3x.png') no-repeat 50%;
        background-size: 19%;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

.upload-panel {
    background: url(../images/bg-upload-panel.png) no-repeat 50% 0 fixed;
    height: 260px;
    position: relative;
    opacity: 0.9;
    font: bold 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
    text-align: center;
    cursor: pointer;
}

    .upload-panel__content {
        padding-top: 105px;
    }

    .upload-panel__title {
        font-size: 32px;
        color: #fff;
    }

    .upload-panel__text {
        font-size: 20px;
        color: #c4a2ee;
    }

.upload-btn {
    background: #ffed00 url(../images/icon-upload@3x.png) no-repeat 50%;
    background-size: 47%;
    width: 62px;
    height: 62px;
    border-radius: 100%;
    cursor: pointer;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -31px;
    transition: background-position 0.3s ease;
}

.upload-btn:hover {
    background-position: 50% 60%;
}

    .upload-btn .fileUploader  {

    }

    .upload-btn .fileContainer {
        background: none;
        box-shadow: none;
        padding: 0;
        margin: 0;
    }

    .upload-btn .uploadIcon {
        display: none;
    }

    .upload-btn .fileContainer p {
        font: 0/0 a;
        margin: 0;
    }

    .upload-btn .fileContainer .chooseFileButton {
        background: none;
        width: 62px;
        height: 62px;
        margin: 0;
        padding: 0;
        border-radius: 100%;
        font: 0/0 a;
    }

    .upload-btn .fileContainer .chooseFileButton:hover {
        background: none;
    }

    .upload-btn .fileContainer .errorsContainer {
        position: absolute;
        top: 112%;
        width: 400px;
        max-width: none;
        text-align: center;
        font-size: 14px;
    }

    .upload-btn .fileContainer .errorMessage  {
        position: relative;
    }

    .upload-btn .fileContainer .errorMessage:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 0;
        box-shadow: 0 0 45px 15px rgba(255, 255, 255, 1);
        z-index: -1;
    }

.card-sign-part {
    max-width: 1249px;
    margin: 56px auto 0;
}

    .card-sign-part__format-part {
        width: 50%;
        float: left;
    }

    .card-sign-part__text-part {
        width: 50%;
        float: right;
    }

.format-part {

}

    .format-part__title {
        font: bold 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        margin-bottom: 34px;
    }

    .format-part__box {
        display: table;
        width: 100%;
    }

    .format-part__box__item {
        display: table-cell;
        vertical-align: top;
        position: relative;
        max-width: 275px;
    }

    .format-part__box__item:first-child {
        z-index: 100;
        height: 392px;
    }

    .format-part__box__item:last-child {
        padding-left: 20px;
        padding-right: 20px;
    }

    .format-part__box__item_h.format-part__box__item:first-child {
        height: 265px;
        margin-bottom: 55px;
    }

    .format-part__box__item_h.format-part__box__item:last-child {
        max-width: none;
    }

    .format-part__box__item_h {
        display: block;
    }

    .format-part__image {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        width: 275px;
        height: 390px;
        display: inline-block;
        vertical-align: top;
        border-radius: 6px;
        border: 1px solid #602f9c;
    }

    .format-part__image_h {

    }

    .format-part__image_0 {
        transform: rotate(0deg);
    }

    .format-part__image_0.format-part__image_h {
        width: 390px;
        height: 275px;
        transform: translate(68px, -13px);
    }

    .format-part__image_90 {
        transform: rotate(90deg);
        width: 390px;
        height: 275px;
        transform-origin: 35.3% 49.9%;
    }

    .format-part__image_90.format-part__image_h {
        width: 275px;
        height: 390px;
        transform-origin: 85.4% 57.1%;
    }

    .format-part__image_180 {
        transform: rotate(180deg);
    }

    .format-part__image_180.format-part__image_h {
        width: 390px;
        height: 275px;
        transform-origin: 58.7% 47.7%;
    }

    .format-part__image_270 {
        transform: rotate(270deg);
        width: 390px;
        height: 275px;
        transform-origin: 50% 70.8%;
    }

    .format-part__image_270.format-part__image_h {
        width: 275px;
        height: 390px;
        transform-origin: 60% 25%;
    }

    .format-part__image__rotator {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        width: 62px;
        height: 62px;
        position: absolute;
        bottom: -32px;
        cursor: pointer;
        transition: transform 0.3s ease;
    }

    .format-part__image__rotator_left {
        background-image: url('../images/icon-rotate-left@3x.png');
        left: -32px;
    }

    .format-part__box__item_h .format-part__image__rotator_left {
        left: 37px;
    }

    .format-part__image__rotator_left:hover {
        transform: rotate( -15deg );
    }

    .format-part__image__rotator_right {
        background-image: url('../images/icon-rotate-right@3x.png');
        right: -16px;
    }

    .format-part__box__item_h .format-part__image__rotator_right {
        right: -215px;
    }

    .format-part__image__rotator_right:hover {
        transform: rotate( 15deg );
    }

    .format-part__description {

    }

    .format-part__box__item_h .format-part__description {
        display: table-cell;
    }

    .format-part__type {
        font: 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        color: #602f9c;
        margin-bottom: 10px;
    }

    .format-part__text {
        font: 12px/1.41 'Roboto', Arial, Helvetica, sans-serif;
        opacity: 0.6;
        margin-bottom: 14px;
    }

.text-part {

}

    .text-part__title {
        font: bold 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        margin-bottom: 34px;
    }

.d-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font: 16px/26px 'Roboto', Arial, Helvetica, sans-serif;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    .d-radio input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
    }

    .d-radio__checkmark {
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        border: 1px solid #602f9c;
        opacity: 0.4;
        transition: opacity 0.3s ease;
    }

    .d-radio:hover input ~ .d-radio__checkmark {
        opacity: 1;
    }

    .d-radio input:checked ~ .d-radio__checkmark {
        background-color: #fff;
        opacity: 1;
    }

    .d-radio__checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    .d-radio input:checked ~ .d-radio__checkmark:after {
        display: block;
    }

    .d-radio .d-radio__checkmark:after {
        background: #602f9c;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 16px;
        height: 16px;
        border-radius: 50%;
    }

.d-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 33px;
    cursor: pointer;
    font: 12px/1.41 'Roboto', Arial, Helvetica, sans-serif;
    color: #858386;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    .d-checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .d-checkbox__checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
    }

    .d-checkbox:hover input ~ .d-checkbox__checkmark {
        background-color: #ccc;
    }

    .d-checkbox input:checked ~ .d-checkbox__checkmark {
        background-color: #602f9c;
    }

    .d-checkbox__checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .d-checkbox input:checked ~ .d-checkbox__checkmark:after {
        display: block;
    }

    .d-checkbox .d-checkbox__checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

.d-chooser {
    border: 1px solid rgba(51, 49, 54, 0.2);
    border-radius: 10px;
    padding: 19px 29px 6px;
    margin-bottom: 20px;
}

    .d-chooser__title {
        font: 14px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        opacity: 0.6;
        margin-bottom: 7px;
        display: block;
    }

    .d-chooser__list {
        max-height: 250px;
        overflow: auto;
    }

    .d-chooser__list__item {

    }

    .d-chooser__list-colors {

    }

    .d-chooser__input-w {

    }

    .d-chooser__input-text {
        font: 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        border: 0;
        outline: 0;
        width: 100%;
    }

    textarea.d-chooser__input-text {
        resize: none;
        height: 120px;
    }

    .d-chooser__text-preview {

    }

    .d-chooser__text-preview img {
        max-width: 100%;
    }

    .d-chooser__font-image {
        height: 34px;
        margin-left: -7px;
    }

    .d-chooser__color {
        float: left;
        cursor: pointer;
    }

    .d-chooser__color__inner {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        margin-right: 10px;
    }

.card-fields-part {
    max-width: 1249px;
    margin: 56px auto 0;
}

    .card-fields-part__title {
        font: bold 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        margin-bottom: 32px;
    }

    .card-fields-part__fields {
        width: 50%;
        float: left;
    }

    .card-fields-part__preview {
        width: 50%;
        float: right;
        margin-bottom: 15px;
    }

    .card-fields-part__preview_center {
        float: none;
        margin-left: auto;
        margin-right: auto;
    }

    .card-fields-part__fields__inner {
        display: table;
        width: 100%;
    }

    .card-fields-part__fields__recipient {
        display: table-cell;
        vertical-align: top;
        width: 50%;
        padding-right: 20px;
    }

    .card-fields-part__fields__sender {
        display: table-cell;
        vertical-align: top;
        width: 50%;
        padding-right: 20px;
    }

.card-preview {

}

.card-preview_h {

}

    .card-preview__title {
        font: bold 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        margin-bottom: 32px;
    }

    .card-preview__wrapper {
        display: table;
        width: 100%;
    }

    .card-preview__wrapper__item {
        display: table-cell;
        vertical-align: top;
    }

    .card-preview_h .card-preview__wrapper__item {
        display: block;
    }

    .card-preview__wrapper__item:first-child {
        width: 275px;
        padding-right: 20px;
    }

    .card-preview_h .card-preview__wrapper__item:first-child {
        height: 286px;
        margin-left: auto;
        margin-right: auto;
        right: 48px;
        position: relative;
    }

    .card-preview__face {

    }

    .card-preview__face__image {
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: contain;
        height: 387px;
        border-radius: 6px;
        margin-bottom: 33px;
        border: 1px solid #602f9c;
    }


    .card-preview_h .card-preview__face__image {
        transform: rotate(-90deg);
        transform-origin: 47.7% 33.4%;
    }

    .card-preview__about {
        display: table;
        width: 100%;
    }

    .card-preview_h .card-preview__about {
        margin-top: 191px;
    }

    .card-preview__about__item {
        display: table-cell;
        vertical-align: top;
        width: 50%;
    }

    .card-preview__about__title {
        font: 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        color: #602f9c;
        margin-bottom: 9px;
    }

    .card-preview__about__text {
        font: 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
    }

    .card-preview__back {

    }

    .card-preview__back__image {
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: contain;
        width: 275px;
        height: 195px;
        border: 1px solid #d6d6d7;
    }

    .card-preview_h .card-preview__back__image {
        width: 392px;
        height: 278px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 33px;
    }

    .card-preview__loader {
        background: url('../images/bg-loader.gif') no-repeat 50%;
        width: 100%;
        height: 385px;
    }

.image-card-popup {
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2000;
}

.image-card-popup_h {

}

    .image-card-popup__w {
        max-width: 70%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 600px;
        text-align: center;
    }

    .image-card-popup__img-outer {
        display: inline-block;
        position: relative;
    }

    .image-card-popup_h .image-card-popup__img-outer {
        transform: rotate(-90deg);
    }

    .image-card-popup__closer {
        background: url(../images/icon-closer-white@3x.png) no-repeat 50%;
        background-size: contain;
        position: absolute;
        right: -45px;
        top: 0;
        width: 35px;
        height: 35px;
        cursor: pointer;
        transition: transform 0.3s ease;
    }

    .image-card-popup_h .image-card-popup__closer {
        top: auto;
        bottom: -45px;
        right: 0;
    }

    .image-card-popup__closer:hover {
        transform: rotate( 90deg );
    }

    .image-card-popup__img-outer img {
        max-width: 100%;
        max-height: 600px;
    }

.d-error-message {
    color: #f00;
    margin-bottom: 18px;
}

/* Loader begins */
@keyframes lds-ellipsis3 {
    0%, 25% {
        left: 32px;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        left: 32px;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    75% {
        left: 100px;
    }
    100% {
        left: 168px;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes lds-ellipsis3 {
    0%, 25% {
        left: 32px;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        left: 32px;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    75% {
        left: 100px;
    }
    100% {
        left: 168px;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    25%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}

@-webkit-keyframes lds-ellipsis2 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    25%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}

@keyframes lds-ellipsis {
    0% {
        left: 32px;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    25% {
        left: 32px;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        left: 100px;
    }
    75% {
        left: 168px;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        left: 168px;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}

@-webkit-keyframes lds-ellipsis {
    0% {
        left: 32px;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    25% {
        left: 32px;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        left: 100px;
    }
    75% {
        left: 168px;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        left: 168px;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}

.lds-ellipsis {
    position: relative;
}

.lds-ellipsis > div {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
}

.lds-ellipsis div > div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #f00;
    position: absolute;
    top: 100px;
    left: 32px;
    -webkit-animation: lds-ellipsis 1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
    animation: lds-ellipsis 1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
}

.lds-ellipsis div:nth-child(1) div {
    -webkit-animation: lds-ellipsis2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
    animation: lds-ellipsis2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
    background: #af6cce;
}

.lds-ellipsis div:nth-child(2) div {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
    background: #ffed00;
}

.lds-ellipsis div:nth-child(3) div {
    -webkit-animation-delay: -0.25s;
    animation-delay: -0.25s;
    background: #af6cce;
}

.lds-ellipsis div:nth-child(4) div {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    background: #ffed00;
}

.lds-ellipsis div:nth-child(5) div {
    -webkit-animation: lds-ellipsis3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
    animation: lds-ellipsis3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
    background: #af6cce;
}

.lds-ellipsis {
    width: 200px !important;
    height: 200px !important;
    margin: 0 auto;
    -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
    transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}
/* Loader ends */

/* Loader begins */
.round-loader {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
  z-index: -1;
}

    .round-loader__circle1, .round-loader__circle2 {
      background-color: #602f9c;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;

      -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
      animation: sk-bounce 2.0s infinite ease-in-out;
    }

    .round-loader__circle2 {
      -webkit-animation-delay: -1.0s;
      animation-delay: -1.0s;
    }

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
/* Loader ends */

body.yandex-checkout_visible {
    filter: none;
}

body.yandex-checkout_visible>:not(.yandex-checkout-ui_status_created):not(.yandex-checkout-tooltip) {
    filter: none;
}

@media ( max-width: 414px ) {
    .yandex-checkout-cardpayment__close {
        top: -25px;
    }
}

.payment-iframe {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2000;
    filter: none;
    left: 0;
    top: 0;
}

    .payment-iframe__closer {
        position: absolute;
        top: 10%;
        right: 10%;
        color: #fff;
        font-size: 26px;
        cursor: pointer;
    }

    .payment-iframe iframe {
        position: absolute;
        width: 80%;
        height: 70%;
        left: 10%;
        top: 15%;
    }

.pay-success {
    background-color: #32d94b;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    max-width: 300px;
    margin: 0 auto 10px;
    padding: 5px 10px;
}

.pay-failed {
    background-color: #f04329;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    max-width: 300px;
    margin: 0 auto 10px;
    padding: 5px 10px;
}

.pay-waiting {
    background-color: #74dfff;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    max-width: 300px;
    margin: 0 auto 10px;
    padding: 5px 10px;
}

.user-cards {
    margin: 56px auto 20px;
    padding: 0 140px;
}

.user-card {
    width: 308px;
    margin: 0 8px 40px;
    float: left;
    display: table;
    color: #333136;
    border-bottom: 0 !important;
}

    .user-card__face {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        width: 120px;
        height: 168px;
        border-radius: 6px;
        box-shadow: 0 5px 5px 0 rgba(51, 49, 54, 0.1);
        display: table-cell;
        vertical-align: top;
    }

    .user-card__face_h {
        transform: rotate(-90deg) scale(0.7);
    }

    .user-card__info {
        display: table-cell;
        vertical-align: top;
        padding: 9px 0 0 21px;
    }

    .user-card__date {
        font-size: 12px;
        line-height: 1.41;
        opacity: 0.6;
        margin-bottom: 10px;
    }

    .user-card__approving-status {
        background-position: 0 50%;
        background-repeat: no-repeat;
        background-size: auto 100%;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 3px;
        padding-left: 25px;
    }

    .user-card__status {
        font-size: 12px;
        line-height: 1.41;
        margin-bottom: 27px;
        padding-left: 25px;
        color: #888;
    }

    .user-card__recipient {

    }

    .user-card__recipient__title {
        font-size: 12px;
        line-height: 1.41;
        color: #333136;
        opacity: 0.6;
        margin-bottom: 3px;
    }

    .user-card__recipient__name {
        font-size: 14px;
        line-height: 1.41;
        overflow: hidden;
        max-height: 38px;
    }

.paginator {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
}

    a.paginator__link:link,
    a.paginator__link:visited,
    a.paginator__link:hover,
    a.paginator__link:active,
    a.paginator__link:focus {
        border-bottom: 0;
        font-size: 20px;
        line-height: 1.125;
        margin: 0 20px 20px;
        display: inline-block;
    }

    a.paginator__link.active:link,
    a.paginator__link.active:visited,
    a.paginator__link.active:hover,
    a.paginator__link.active:active,
    a.paginator__link.active:focus {
        background: #602f9c;
        color: #fff;
        padding: 5px 7px;
        margin-left: 13px;
        margin-right: 13px;
        border-radius: 3px;
    }

.soc-sharing {
    background: #fff;
    position: fixed;
    z-index: 800;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

    .soc-sharing__inner {
        position: absolute;
        width: 760px;
        height: 390px;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .soc-sharing__content {
        display: table-cell;
        width: 275px;
        vertical-align: middle;
    }

    .soc-sharing__buttons {
        display: table-cell;
        vertical-align: middle;
        padding-left: 40px;
        color: #333136;
    }

    .soc-sharing__title {
        font: bold 32px/1.41 'Roboto', Arial, Helvetica, sans-serif;
        margin-bottom: 10px;
    }

    .soc-sharing__description {
        font: bold 20px/1.41 'Roboto', Arial, Helvetica, sans-serif;
        opacity: 0.6;
        margin-bottom: 45px;
    }

    .soc-sharing__inner .SocialMediaShareButton {
        display: inline-block;
        outline: none;
    }

    .soc-sharing__item {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        width: 84px;
        height: 84px;
        margin-right: 27px;
        cursor: pointer;
        border-radius: 100%;
        transition: transform 0.3s ease;
        display: inline-block;
    }

    .soc-sharing__item:hover {
        transform: rotate(45deg);
    }

    .soc-sharing__item_fb {
        background-image: url('../images/icon-soc-fb@3x.png');
        background-color: #3b5998;
        background-size: 40px;
    }

    .soc-sharing__item_tw {
        background-image: url('../images/icon-soc-tw@3x.png');
        background-color: #1da1f2;
        background-size: 42px;
    }

    .soc-sharing__item_vk {
        background-image: url('../images/icon-soc-vk@3x.png');
        background-color: #6383a8;
        background-size: 44px;
    }

    .soc-sharing__item_ok {
        background-image: url('../images/icon-soc-ok@3x.png');
        background-color: #f4731c;
        background-size: 23px;
    }

.sharing-postcard {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    width: 275px;
    height: 390px;
}

.content-page {
    max-width: 80%;
    margin: 55px auto;
}

.about-service {
    max-width: 800px;
}

.old-browsers-notice {
    background-color: #f00;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1200;
}





/*pages ends*/










