.is-mobile h1,
.is-mobile .title {
    font-size: 24px;
}

.is-mobile .button,
.is-mobile .button:link,
.is-mobile .button:visited,
.is-mobile .button:hover,
.is-mobile .button:active,
.is-mobile .button:focus {
    background: #602f9c;
    box-shadow: 0 10px 10px 0 rgba(96, 47, 156, 0.2);
    font: bold 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
    color: #fff;
    display: inline-block;
    padding: 20px 97px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
}

.is-mobile .mb20 {
    margin-bottom: 20px !important;
}

.is-mobile .width-full {
    width: 100% !important;
}

.is-mobile.page-wrapper {
    padding-bottom: 50px;
    min-width: 0;
}

.is-mobile .header {
    background: #602f9c;
    padding: 13px 15px;
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
}

    .is-mobile .header__controls {
        background-color: inherit;
        padding: 24px 15px 8px;
        position: absolute;
        top: 98%;
        left: 0;
        right: 0;
        border-radius: 0 0 10px 10px;
        box-shadow: 0 10px 10px 0 rgba(96, 47, 156, 0.2);
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease;
        float: none;
    }

    .is-mobile .header__controls.active {
        visibility: visible;
        opacity: 1;
    }

.is-mobile .header-filler {
    height: 60px;
}

.is-mobile .logo,
.is-mobile .logo:link,
.is-mobile .logo:visited,
.is-mobile .logo:hover,
.is-mobile .logo:active,
.is-mobile .logo:focus {
    background: url('../images/icon-logo@3x.png') no-repeat 0 50%;
    background-size: 39px 25px;
    min-height: 25px;
    float: left;
    border-bottom: 0;
    font: bold 14px/1.125 'Roboto', Arial, Helvetica, sans-serif;
    color: #fff;
    padding-left: 50px;
}

    .is-mobile .logo__about {
        display: block;
        font-size: 10px;
        opacity: 0.6;
    }

.is-mobile .i-link,
.is-mobile .i-link:link,
.is-mobile .i-link:visited,
.is-mobile .i-link:hover,
.is-mobile .i-link:active,
.is-mobile .i-link:focus {
    background-position: 0 50%;
    background-size: auto 24px;
    background-repeat: no-repeat;
    min-height: 24px;
    padding-left: 35px;
    margin-bottom: 24px;
    font: bold 16px/24px 'Roboto', Arial, Helvetica, sans-serif;
    color: #fff;
    display: block;
    vertical-align: top;
    border-bottom: 0;
}

    .is-mobile .preloaded-cards__inner {

    }

    .is-mobile .preloaded-cards__title {
        font: bold 12px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        text-transform: uppercase;
        text-align: center;
        margin: 24px 0 14px;
    }

    .is-mobile .preloaded-cards__row {
        width: 100%;
        overflow: auto;
        white-space: nowrap;
        /*-webkit-overflow-scrolling: touch;*/ /* иначе изображения пропадают, т.к. они data uri */
        padding-left: 15px;
        margin-bottom: -15px;
        box-sizing: border-box;
    }

.is-mobile .preloaded-card {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 138px;
    height: 195px;
    display: inline-block;
    vertical-align: top;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    margin-right: 14px;
    margin-bottom: 15px;
    box-shadow: 0 5px 5px 0 rgba(51, 49, 54, 0.1);
}

.is-mobile .text-input-help {
    margin-bottom: 39px;
    position: relative;
}

.is-mobile .auth-popup-w {
    background: rgba(51, 49, 54, 0.3);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1500;
    overflow: scroll;
}

.is-mobile .auth-popup {
    background: #fff;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 120px;
    width: 100%;
    box-sizing: border-box;
    padding: 32px 28px;
}

.is-mobile .upload-panel {
    background: url(../images/bg-upload-panel.png) no-repeat 50% 0 fixed;
    height: 140px;
    position: relative;
    opacity: 0.9;
    font: bold 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
    text-align: center;
}

    .is-mobile .upload-panel__content {
        padding-top: 73px;
    }

    .is-mobile .upload-panel__title {
        font-size: 18px;
        color: #fff;
        margin-bottom: 7px;
    }

    .is-mobile .upload-panel__text {
        font-size: 12px;
        color: #c4a2ee;
    }

.is-mobile .upload-btn {
    background: #ffed00 url(../images/icon-upload@3x.png) no-repeat 50%;
    background-size: 47%;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    cursor: pointer;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 76px;
    transition: background-position 0.3s ease;
}

.is-mobile .card-sign-part {
    max-width: none;
    margin: 49px auto 0;
    padding: 0 15px;
}

    .is-mobile .card-sign-part__format-part {
        width: auto;
        float: none;
    }

    .is-mobile .card-sign-part__text-part {
        width: auto;
        float: none;
    }

.is-mobile .format-part {
    margin-bottom: 32px;
}

    .is-mobile .format-part__title {
        font: bold 14px/1.41 'Roboto', Arial, Helvetica, sans-serif;
        margin-bottom: 24px;
    }

    .is-mobile .format-part__box {
        overflow: hidden;
        display: block;
        width: auto;
    }

    .is-mobile .format-part__box__item {
        position: relative;
        max-width: 240px;
        margin: 0 auto;
        display: block;
    }

    .is-mobile .format-part__box__item:first-child {
        z-index: 100;
        height: 340px;
        margin-bottom: 44px;
    }

    @media (max-width: 380px) {
        .is-mobile .format-part__box__item:first-child {
            transform: scale(0.83);
        }
    }

    .is-mobile .format-part__box__item:last-child {
        max-width: none;
        padding-left: 0;
        padding-right: 0;
    }

    .is-mobile .format-part__box__item_h.format-part__box__item:first-child {
        height: 265px;
        margin-bottom: 55px;
    }

    .is-mobile .format-part__box__item_h.format-part__box__item:last-child {
        max-width: none;
    }

    .is-mobile .format-part__box__item_h {
        display: block;
    }

    .is-mobile .format-part__image {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        width: 240px;
        height: 340px;
        display: inline-block;
        vertical-align: top;
        border-radius: 6px;
        border: 1px solid #602f9c;
    }

    .is-mobile .format-part__image_h {

    }

    .is-mobile .format-part__image_0 {
        transform: rotate(0deg);
    }

    .is-mobile .format-part__image_0.format-part__image_h {
        width: 340px;
        height: 240px;
        transform: translate(-51px, 7.5px);
    }

    .is-mobile .format-part__image_90 {
        transform: rotate(90deg);
        width: 340px;
        height: 240px;
        transform-origin: 35.3% 49.9%;
    }

    .is-mobile .format-part__image_90.format-part__image_h {
        width: 240px;
        height: 340px;
        transform-origin: 58.4% 43.7%;
    }

    .is-mobile .format-part__image_180 {
        transform: rotate(180deg);
    }

    .is-mobile .format-part__image_180.format-part__image_h {
        width: 340px;
        height: 240px;
        transform-origin: 42.5% 51.6%;
    }

    .is-mobile .format-part__image_270 {
        transform: rotate(270deg);
        width: 340px;
        height: 240px;
        transform-origin: 50% 70.8%;
    }

    .is-mobile .format-part__image_270.format-part__image_h {
        width: 240px;
        height: 340px;
        transform-origin: 41% 44.0%;
    }

    .is-mobile .format-part__image__rotator {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        width: 40px;
        height: 40px;
        position: absolute;
        bottom: -22px;
        cursor: pointer;
        transition: transform 0.3s ease;
    }

    .is-mobile .format-part__box__item_h .format-part__image__rotator {
        bottom: -32px;
    }

    .is-mobile .format-part__image__rotator_left {
        background-image: url('../images/icon-rotate-left@3x.png');
        left: -28px;
    }

    .is-mobile .format-part__box__item_h .format-part__image__rotator_left {
        left: -52px;
    }

    .is-mobile .format-part__image__rotator_left:hover {
        transform: rotate( -15deg );
    }

    .is-mobile .format-part__image__rotator_right {
        background-image: url('../images/icon-rotate-right@3x.png');
        right: -28px;
    }

    .is-mobile .format-part__box__item_h .format-part__image__rotator_right {
        right: -52px;
    }

    .is-mobile .format-part__image__rotator_right:hover {
        transform: rotate( 15deg );
    }

    .is-mobile .format-part__description {

    }

    .is-mobile .format-part__box__item_h .format-part__description {
        display: block;
    }

    .is-mobile .format-part__type {
        font: 14px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        color: #602f9c;
        margin-bottom: 10px;
    }

    .is-mobile .format-part__text {
        font: 12px/1.41 'Roboto', Arial, Helvetica, sans-serif;
        opacity: 0.6;
        margin-bottom: 14px;
    }

    .is-mobile .text-part__title {
        font: bold 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        margin-bottom: 20px;
    }

.is-mobile .d-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font: 14px/26px 'Roboto', Arial, Helvetica, sans-serif;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.is-mobile .d-chooser {
    border: 1px solid rgba(51, 49, 54, 0.2);
    border-radius: 10px;
    padding: 16px 20px 3px;
    margin-bottom: 14px;
}

    .is-mobile .d-chooser__title {
        font: 12px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        opacity: 0.6;
        margin-bottom: 7px;
        display: block;
    }

    .is-mobile .d-chooser__list-colors {
        margin-left: -3px;
    }

    .is-mobile .d-chooser__input-text {
        font: 14px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        border: 0;
        outline: 0;
        width: 100%;
    }

    .is-mobile .d-chooser__color__inner {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        margin: 0 5px 8px;
    }

.is-mobile .card-fields-part {
    max-width: none;
    margin: 36px auto 0;
}

    .is-mobile .card-fields-part__fields {
        margin-bottom: 40px;
        width: auto;
        float: none;
    }

    .is-mobile .card-fields-part__preview {
        width: auto;
        float: none;
    }

    .is-mobile .card-fields-part__fields__inner {
        padding: 0 15px;
        display: block;
        width: auto;
    }

    .is-mobile .card-fields-part__fields__recipient {
        display: block;
        width: auto;
        padding-right: 0;
    }

    .is-mobile .card-fields-part__fields__sender {
        display: block;
        width: auto;
        padding-right: 0;
    }

.is-mobile .card-preview {
    padding: 0 15px;
}

.is-mobile .card-preview_h {
    padding-left: 5px;
    padding-right: 5px;
}

    .is-mobile .card-preview__title {
        font: bold 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        margin-bottom: 20px;
        text-align: center;
    }

    .is-mobile .card-preview__wrapper {
        overflow: hidden;
        display: block;
        width: auto;
    }

    .is-mobile .card-preview__wrapper__item {
        width: 290px;
        margin: 0 auto;
        display: block;
    }

    .is-mobile .card-preview_h .card-preview__wrapper__item {
        display: block;
        width: 300px;
    }

    .is-mobile .card-preview__wrapper__item:first-child {
        padding-right: 0;
    }

    .is-mobile .card-preview_h .card-preview__wrapper__item:first-child {
        width: 212px;
        margin-bottom: -71px;
        right: auto;
        height: auto;
    }

    .is-mobile .card-preview__face {

    }

    .is-mobile .card-preview__face__image {
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: contain;
        height: 408px;
        border-radius: 6px;
        margin-bottom: 24px;
        border: 1px solid #602f9c;
    }


    .is-mobile .card-preview_h .card-preview__face__image {
        height: 298px;
        transform: rotate(-90deg);
        transform-origin: 44% 45.55%;
    }

    .is-mobile .card-preview__about {
        display: table;
        width: 100%;
        margin-top: 0;
        margin-bottom: 39px;
    }

    .is-mobile .card-preview_h .card-preview__about {

    }

    .is-mobile .card-preview__about__item {
        display: table-cell;
        vertical-align: top;
        width: 50%;
    }

    .is-mobile .card-preview__about__title {
        font: 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
        color: #602f9c;
        margin-bottom: 9px;
    }

    .is-mobile .card-preview__about__text {
        font: 16px/1.125 'Roboto', Arial, Helvetica, sans-serif;
    }

    .is-mobile .card-preview__back {

    }

    .is-mobile .card-preview__back__image {
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: contain;
        width: 288px;
        height: 205px;
        border: 1px solid #d6d6d7;
        margin: 0 auto 24px;
    }

    .is-mobile .card-preview_h .card-preview__back__image {
        width: 300px;
        height: 212px;
    }

    .is-mobile .card-preview__loader {
        background: url('../images/bg-loader.gif') no-repeat 50%;
        width: 100%;
        height: 385px;
    }

.is-mobile .image-card-popup {
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2000;
}

.is-mobile .image-card-popup_h {

}

    .is-mobile .image-card-popup__w {
        max-width: 98%;
        max-height: 98%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        padding-top: 85px;
        height: 600px;
        text-align: center;
        overflow: auto;
    }

    .is-mobile .image-card-popup__img-outer {
        display: inline-block;
        position: relative;
    }

    .is-mobile .image-card-popup_h .image-card-popup__img-outer {
        transform: rotate(-90deg) scale(0.7) translateY(2px);
    }

    .is-mobile .image-card-popup__closer {
        background: url(../images/icon-closer-white@3x.png) no-repeat 50%;
        background-size: contain;
        position: absolute;
        right: 0;
        top: -45px;
        width: 35px;
        height: 35px;
        cursor: pointer;
        transition: transform 0.3s ease;
    }

    .is-mobile .image-card-popup_h .image-card-popup__closer {
        top: 90%;
        right: -45px;
    }

    .is-mobile .image-card-popup__closer:hover {
        transform: rotate( 90deg );
    }

    .is-mobile .image-card-popup__img-outer img {
        max-width: 100%;
        max-height: 600px;
    }

.is-mobile .menu-btn {
    background: url('../images/icon-menu-btn@3x.png') no-repeat 50%;
    background-size: contain;
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 24px;
    height: 19px;
    transition: background 0.3s ease;
}

.is-mobile .menu-btn.active {
    background-image: url('../images/icon-closer-white-m@3x.png');
}

.is-mobile .payment-iframe {

}

    .is-mobile .payment-iframe__closer {
        top: 7%;
    }

.is-mobile .user-cards {
    margin-top: 24px;
    padding: 0 5px;
}

.is-mobile .user-card {
    margin-bottom: 20px;
}

.is-mobile .soc-sharing {
    text-align: center;
    overflow: scroll;
}

    .is-mobile .soc-sharing__inner {
        padding-top: 110px;
        padding-bottom: 30px;
        position: static;
        width: auto;
        height: auto;
        display: block;
    }

    .is-mobile .soc-sharing__content {
        display: block;
        width: auto;
    }

    .is-mobile .soc-sharing__buttons {
        display: block;
        padding-left: 0;
    }

    .is-mobile .soc-sharing__title {
        font: bold 18px/1.41 'Roboto', Arial, Helvetica, sans-serif;
        margin-bottom: 3px;
    }

    .is-mobile .soc-sharing__description {
        font: bold 14px/1.41 'Roboto', Arial, Helvetica, sans-serif;
        opacity: 0.6;
        margin-bottom: 20px;
    }

    .is-mobile .soc-sharing__item {
        width: 50px;
        height: 50px;
        margin-right: 15px;
        transition: none;
    }

    .is-mobile .soc-sharing__item:hover {
        transform: none;
    }

    .is-mobile .soc-sharing__item_fb {
        background-size: 24px;
    }

    .is-mobile .soc-sharing__item_tw {
        background-size: 25px;
    }

    .is-mobile .soc-sharing__item_vk {
        background-size: 26px;
    }

    .is-mobile .soc-sharing__item_ok {
        background-size: 14px;
    }

.is-mobile .sharing-postcard {
    width: 180px;
    height: 252px;
    margin: 0 auto 24px;
}

.is-mobile .content-page {
    max-width: 100%;
    margin: 24px auto;
    padding: 0 15px;
}

.mock-download-apps {
    background: url('../images/bg-mock-download-apps.jpg') no-repeat 50% 100%;
    background-size: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 4000;
    color: #ffed00;
    font-size: 20px;
    line-height: 1.2;
}

    .mock-download-apps__content {
        position: absolute;
        bottom: 25px;
        left: 10px;
        right: 10px;
        text-align: center;
    }

    .mock-download-apps__title {
        margin-bottom: 15px;
    }








/*pages ends*/










